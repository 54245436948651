export default [ 
  // {
  //   picture_url: process.env.PUBLIC_URL + '/nft/pro4/1.gif',
  //   title: "ShihNFT No.1 WAPO",
  //   number: "",
  //   desc: '',
  //   link: '/nft/products/category/1',
  //   mediaType: 1,
  //   id: 1
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + '/nft/pro4/2.gif',
  //   title: "ShihNFT No.2 water-color-cats",
  //   number: "",
  //   desc: '',
  //   link: '/nft/products/category/1',
  //   mediaType: 1,
  //   id: 2
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + '/nft/pro4/3.gif',
  //   title: "ShihNFT No.3 Crypto Punk Doggie",
  //   number: "",
  //   desc: '',
  //   link: '/nft/products/category/1',
  //   mediaType: 1,
  //   id: 3
  // },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro4/4.gif',
    title: "ShihNFT No.4 The Not-A-Rocket Boots",
    number: "",
    desc: '',
    link: 'https://www.teaswap.live/hotbids',
    mediaType: 1,
    id: 4
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro4/5.gif',
    title: "ShihNFT No.5 lightning -9000",
    number: "",
    desc: '',
    link: 'https://www.teaswap.live/hotbids',
    mediaType: 1,
    id: 5
  },
  // {
  //   picture_url: process.env.PUBLIC_URL + '/nft/pro4/6.gif',
  //   title: "ShihNFT No.6 Neon",
  //   number: "",
  //   desc: '',
  //   link: 'https://www.teaswap.live/hotbids',
  //   mediaType: 1,
  //   id: 6
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + '/nft/pro4/7.gif',
  //   title: "ShihNFT No.7 Moon Taro -Scientific name Cricetinae -C",
  //   number: "",
  //   desc: '',
  //   link: 'https://www.teaswap.live/hotbids',
  //   mediaType: 1,
  //   id: 7
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + '/nft/pro4/8.gif',
  //   title: "ShihNFT No.8 London Punk Doggie",
  //   number: "",
  //   desc: '',
  //   link: '/nft/products/category/1',
  //   mediaType: 1,
  //   id: 8
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + '/nft/pro4/9.gif',
  //   title: "ShihNFT No.9 Window",
  //   number: "",
  //   desc: '',
  //   link: '/nft/products/category/1',
  //   mediaType: 1,
  //   id: 9
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + '/nft/pro4/10.gif',
  //   title: "ShihNFT No.10 -Tzu-Pupogz",
  //   number: "",
  //   desc: '',
  //   link: '/nft/products/category/1',
  //   mediaType: 1,
  //   id: 10
  // },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro4/11.gif',
    title: "ShihNFT #11-Token-Night",
    number: "",
    desc: '',
    link: '/nft/products/category/1',
    mediaType: 1,
    id: 11
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro4/12.gif',
    title: "ShihNFt No.12 -Token-Daytime",
    number: "",
    desc: '',
    link: '/nft/products/category/1',
    mediaType: 1,
    id: 12
  },
  // {
  //   picture_url: process.env.PUBLIC_URL + '/nft/pro4/13.gif',
  //   title: "ShihNFT No. 13 Tail",
  //   number: "",
  //   desc: '',
  //   link: '/nft/products/category/1',
  //   mediaType: 1,
  //   id: 13
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + '/nft/pro4/14.gif',
  //   title: "ShihNFT No.14 skipping",
  //   number: "",
  //   desc: '',
  //   link: '/nft/products/category/1',
  //   mediaType: 1,
  //   id: 14
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + '/nft/pro4/15.gif',
  //   title: "Shih CryptoPunk No. 17",
  //   number: "",
  //   desc: 'Jelly King-Scientific Name Gelatin Ursidae',
  //   link: '/nft/products/category/1',
  //   mediaType: 1,
  //   id: 15
  // },
  
]
