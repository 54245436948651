export default [
  {
    picture_url: "https://teaswap.mypinata.cloud/ipfs/QmS8D7NrBWHLPMioBz6pfo9cLZDAgq2tmu2qkDy93jbo4V",
    title: "HotBoxOg Album",
    number: "",
    desc: "",
    link: "https://www.xtinct.io/",
    mediaType: 1,
    id: -2,
  },  {
    picture_url: "https://teaswap.mypinata.cloud/ipfs/QmcFE9rMcM4LsVXyszCkgDLMc5YXNLjdiwtpw7PQTRDkUq",
    title: "Web3Dictionary Album",
    number: "",
    desc: "",
    link: "https://www.teaswap.art/iro",
    mediaType: 1,
    id: -1,
  },
  {
    picture_url: "https://teaswap.mypinata.cloud/ipfs/QmT4mKuAGiKsG9QitfHxBQXNSct2At2Ez9VETHL1NhUvyg",
    title: "Web3Dictionary Album",
    number: "",
    desc: "",
    link: "https://www.teaswap.art/iro",
    mediaType: 1,
    id:0
  },
  // {
  //   picture_url: "https://teaswap.mypinata.cloud/ipfs/QmS3Cgd3VjFbFEJE2ALG8rVySZ4QTgaNWUcJr9hANxP1gZ/4.gif",
  //   title: "Web3Dictionary Album",
  //   number: "",
  //   desc: "",
  //   link: "https://www.teaswap.art/iro",
  //   mediaType: 1,
  //   id:0
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + "/nft/pro1/w1.png",
  //   title: "Penguin Punk #27",
  //   number: "",
  //   desc: "",
  //   link: "https://www.teaswap.art/nft/products/category/1",
  //   mediaType: 1,
  //   id: 1,
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + "/nft/pro1/w2.png",
  //   title: "Penguin Punk #26",
  //   number: "",
  //   desc: "",
  //   link: "https://www.teaswap.art/nft/products/category/1",
  //   mediaType: 1,
  //   id: 2,
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + "/nft/pro1/w3.png",
  //   title: "Penguin Punk #25",
  //   number: "",
  //   desc: "",
  //   link: "https://www.teaswap.art/nft/products/category/1",
  //   mediaType: 1,
  //   id: 3,
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + "/nft/pro1/w4.png",
  //   title: "Penguin Punk #24",
  //   number: "",
  //   desc: "",
  //   link: "https://www.teaswap.art/nft/products/category/1",
  //   mediaType: 1,
  //   id: 4,
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + "/nft/pro1/w5.png",
  //   title: "Penguin Punk #23",
  //   number: "",
  //   desc: "",
  //   link: "https://www.teaswap.art/nft/products/category/1",
  //   mediaType: 1,
  //   id: 5,
  // },
  //
  // {
  //   picture_url: process.env.PUBLIC_URL + "/nft/pro1/1.jpg",
  //   title: "Hamburg Penguin #20",
  //   number: "",
  //   desc: "",
  //   link: "https://www.teaswap.art/nft/products/category/1",
  //   mediaType: 1,
  //   id: 6,
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + "/nft/pro1/2.jpg",
  //   title: "Hot Dog Penguin #19",
  //   number: "",
  //   desc: "",
  //   link: "https://www.teaswap.art/nft/products/category/1",
  //   mediaType: 1,
  //   id: 7,
  // },
  {
    picture_url: process.env.PUBLIC_URL + "/nft/pro1/3.jpg",
    title: "Milkshake Penguin #18",
    number: "",
    desc: "",
    link: "https://www.teaswap.art/nft/products/category/1",
    mediaType: 1,
    id: 8,
  },
  // {
  //   picture_url: process.env.PUBLIC_URL + "/nft/pro1/4.jpg",
  //   title: "Chinese Food Penguin #17",
  //   number: "",
  //   desc: "",
  //   link: "https://www.teaswap.art/nft/products/category/1",
  //   mediaType: 1,
  //   id: 9,
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + "/nft/pro1/5.jpg",
  //   title: "Sushi Penguin #16",
  //   number: "",
  //   desc: "",
  //   link: "https://www.teaswap.art/nft/products/category/1",
  //   mediaType: 1,
  //   id: 10,
  // },
  //
  // {
  //   picture_url: process.env.PUBLIC_URL + "/nft/pro1/1.gif",
  //   title: "CJAI NFT Collectibles",
  //   number: "No. 0605",
  //   desc: "TSA NFT Studio",
  //   link: "/nft/products/category/5",
  //   mediaType: 1,
  //   id: 11,
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + "/nft/pro1/haha.gif",
  //   title: "TSANFT",
  //   number: "No.73",
  //   desc: "",
  //   link: "https://www.teaswap.art/nft/products/category/1",
  //   mediaType: 1,
  //   id: 12,
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + "/nft/pro1/Rhythm.gif",
  //   title: "TSANFT",
  //   number: "No.74",
  //   desc: "",
  //   link: "https://www.teaswap.art/nft/products/category/1",
  //   mediaType: 1,
  //   id: 13,
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + "/nft/pro1/56 Shark penguin.png",
  //   title: "Shark penguin No. 56",
  //   number: "",
  //   desc: ``,
  //   link: "https://www.teaswap.live/hotbids-digitalnft",
  //   mediaType: 1,
  //   id: 14,
  // },
  // {
  //   picture_url: process.env.PUBLIC_URL + "/nft/pro1/57 scubadiver penguin.png",
  //   title: "scubadiver penguin No. 57",
  //   number: "",
  //   desc: ``,
  //   link: "https://www.teaswap.live/hotbids-digitalnft",
  //   mediaType: 1,
  //   id: 15,
  // },
];
