export default [ 
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/1.jpg',
    title: "TSALOT NFT#170",
    number: "",
    desc: '',
    link: 'https://www.teaswap.art/nft/products/category/1',
    mediaType: 1,
    id: 1,
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/2.jpg',
    title: "TSALOT NFT#169",
    number: "",
    desc: '',
    link: 'https://www.teaswap.art/nft/products/category/1',
    mediaType: 1,
    id: 2,
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/3.jpg',
    title: "TSALOT NFT#168",
    number: "",
    desc: '',
    link: 'https://www.teaswap.art/nft/products/category/1',
    mediaType: 1,
    id: 3,
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/4.jpg',
    title: "TSALOT NFT#167",
    number: "",
    desc: '',
    link: 'https://www.teaswap.art/nft/products/category/1',
    mediaType: 1,
    id: 4,
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/5.jpg',
    title: "TSALOT NFT#166",
    number: "",
    desc: '',
    link: 'https://www.teaswap.art/nft/products/category/1',
    mediaType: 1,
    id: 5,
  },
  
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/2.gif',
    title: "TSANFT No. 7 Broadway River",
    number: "",
    desc: '',
    link: '/nft/products/311',
    mediaType: 1,
    id: 6,
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/3.gif',
    title: "TSANFT No.4 Doge Shop",
    number: "",
    desc: '',
    link: '/nft/products/category/1',
    mediaType: 1,
    id: 7,
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/4.gif',
    title: "TSANFT No.5 CJAI",
    number: "",
    desc: '',
    link: '/nft/products/308',
    mediaType: 1,
    id: 8,
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/5.gif',
    title: "TSANFT No.3 BiKi Shop",
    number: "",
    desc: '',
    link: '/nft/products/305',
    mediaType: 1,
    id: 9,
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/6.gif',
    title: "TSANFT No.1 Voyage to Metaverse",
    number: "",
    desc: '',
    link: '/nft/products/category/1',
    mediaType: 1,
    id: 10,
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/7.gif',
    title: "TSANFT No.2 Voyage to Metaverse",
    number: "",
    desc: '',
    link: '/nft/products/category/1',
    mediaType: 1,
    id: 11,
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/8.gif',
    title: "TSANFT No.6 BNB Shop",
    number: "",
    desc: '',
    link: '/nft/products/304',
    mediaType: 1,
    id: 12,
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/9.gif',
    title: "TSANFT No. 9 TSA Club",
    number: "",
    desc: ``,
    link: '/nft/products/category/1',
    mediaType: 1,
    id: 13
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/Norse mythology No.86.jpg',
    title: "Norse Mythology NFT Frigg No. 5",
    number: "",
    desc: ``,
    link: 'https://www.teaswap.art/nft/products/vendor/264197',
    mediaType: 1,
    id: 14
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/Norse mythology No.88.jpg',
    title: "Norse Mythology NFT Odin No. 4",
    number: "",
    desc: ``,
    link: 'https://www.teaswap.art/nft/products/vendor/264197',
    mediaType: 1,
    id: 15
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/Norse mythology No.89.png',
    title: "Norse Mythology NFT Búri No. 6",
    number: "",
    desc: ``,
    link: 'https://www.teaswap.art/nft/products/vendor/264197',
    mediaType: 1,
    id: 16
  },
  {
    picture_url: process.env.PUBLIC_URL + '/nft/pro2/Norse mythology No.87.png',
    title: "Norse Mythology NFT Ymir No. 2",
    number: "",
    desc: ``,
    link: 'https://www.teaswap.art/nft/products/vendor/264197',
    mediaType: 1,
    id: 17
  },
  
]