import { CurrencyAmount, JSBI, Token, Trade } from "@teaswap/uniswap-sdk";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ArrowDown } from "react-feather";
import ReactGA from "react-ga";
import { Text } from "rebass";
import { ThemeContext } from "styled-components";
import { useTranslation } from "react-i18next";
// import EthereumLogo from '../../assets/images/bnb.svg'

import AddressInputPanel from "../../components/AddressInputPanel";
import {
  ButtonError,
  ButtonLight,
  ButtonPrimary,
  ButtonConfirmed,
} from "../../components/Button";
import Card, { GreyCard } from "../../components/Card";
import Column, { AutoColumn } from "../../components/Column";
import ConfirmSwapModal from "../../components/swap/ConfirmSwapModal";
import CurrencyInputPanel from "../../components/CurrencyInputPanel";
import { SwapPoolTabs } from "../../components/NavigationTabs";
import { AutoRow, RowBetween } from "../../components/Row";
import AdvancedSwapDetailsDropdown from "../../components/swap/AdvancedSwapDetailsDropdown";
import BetterTradeLink, {
  DefaultVersionLink,
} from "../../components/swap/BetterTradeLink";
import confirmPriceImpactWithoutFee from "../../components/swap/confirmPriceImpactWithoutFee";
import {
  ArrowWrapper,
  BottomGrouping,
  SwapCallbackError,
  Wrapper,
} from "../../components/swap/styleds";
import TradePrice from "../../components/swap/TradePrice";
import TokenWarningModal from "../../components/TokenWarningModal";
import ProgressSteps from "../../components/ProgressSteps";

import {
  BETTER_TRADE_LINK_THRESHOLD,
  INITIAL_ALLOWED_SLIPPAGE,
} from "../../constants";
import { getTradeVersion, isTradeBetter } from "../../data/V1";
import { useCurrency } from "../../hooks/Tokens";
import {
  ApprovalState,
  useApproveCallbackFromTrade,
} from "../../hooks/useApproveCallback";
import useENSAddress from "../../hooks/useENSAddress";
import { useSwapCallback } from "../../hooks/useSwapCallback";
import useToggledVersion, {
  DEFAULT_VERSION,
  Version,
} from "../../hooks/useToggledVersion";
import useWrapCallback, { WrapType } from "../../hooks/useWrapCallback";
import {
  useToggleSettingsMenu,
  useWalletModalToggle,
} from "../../state/application/hooks";
import { Field } from "../../state/swap/actions";
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
} from "../../state/swap/hooks";
import {
  useExpertModeManager,
  useUserSlippageTolerance,
} from "../../state/user/hooks";
import { LinkStyledButton, TYPE } from "../../theme";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import {
  computeTradePriceBreakdown,
  warningSeverity,
} from "../../utils/prices";
import { ClickableText } from "../Pool/styleds";
import Loader from "../../components/Loader";
import styled from "styled-components";

import { filterTokens } from "../../components/SearchModal/filtering";
import { useAllTokens } from "../../hooks/Tokens";
import { MEDIA_QUERY } from "../../constants/style";
import { addTSA } from "../../utils/addTokenToWallet";

import { useActiveWeb3React } from "../../hooks";
import WalletSwitch from "../../WalletSwitch";
import {useNavigate} from "react-router";

// import SwapLogo from '../../assets/images/TEAsWAP.jpeg'
import BNBBridge from '../../assets/images/IMG_5551.jpg'
import BuyCryptoImg from '../../assets/images/IMG_5550.jpg'

export default function Swap() {
  const loadedUrlParams = useDefaultsFromURLSearch();
  const { t } = useTranslation();
  const navigate = useNavigate()

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ];
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(
    false
  );
  const urlLoadedTokens: Token[] = useMemo(
    () =>
      [loadedInputCurrency, loadedOutputCurrency]?.filter(
        (c): c is Token => c instanceof Token
      ) ?? [],
    [loadedInputCurrency, loadedOutputCurrency]
  );
  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true);
  }, []);

  const { account } = useActiveWeb3React();
  const theme = useContext(ThemeContext);

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalToggle();

  // for expert mode
  const toggleSettings = useToggleSettingsMenu();
  const [isExpertMode] = useExpertModeManager();

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance();

  // swap state
  const { independentField, typedValue, recipient } = useSwapState();
  const {
    v1Trade,
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo();
  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;
  const { address: recipientAddress } = useENSAddress(recipient);
  const toggledVersion = useToggledVersion();
  const tradesByVersion = {
    [Version.v1]: v1Trade,
    [Version.v2]: v2Trade,
  };
  const trade = showWrap ? undefined : tradesByVersion[toggledVersion];
  const defaultTrade = showWrap ? undefined : tradesByVersion[DEFAULT_VERSION];

  const betterTradeLinkVersion: Version | undefined =
    toggledVersion === Version.v2 &&
    isTradeBetter(v2Trade, v1Trade, BETTER_TRADE_LINK_THRESHOLD)
      ? Version.v1
      : toggledVersion === Version.v1 && isTradeBetter(v1Trade, v2Trade)
      ? Version.v2
      : undefined;

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]:
          independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]:
          independentField === Field.OUTPUT
            ? parsedAmount
            : trade?.outputAmount,
      };

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient,
  } = useSwapActionHandlers();
  const isValid = !swapInputError;
  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput]
  );
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  // modal and loading
  const [
    { showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash },
    setSwapState,
  ] = useState<{
    showConfirm: boolean;
    tradeToConfirm: Trade | undefined;
    attemptingTxn: boolean;
    swapErrorMessage: string | undefined;
    txHash: string | undefined;
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  });

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ""
      : parsedAmounts[dependentField]?.toSignificant(6) ?? "",
  };

  const route = trade?.route;
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  );
  const noRoute = !route;

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(
    trade,
    allowedSlippage
  );

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false);

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true);
    }
  }, [approval, approvalSubmitted]);

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT]
  );
  const atMaxAmountInput = Boolean(
    maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput)
  );

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    recipient
  );

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade);

  const handleSwap = useCallback(() => {
    if (
      priceImpactWithoutFee &&
      !confirmPriceImpactWithoutFee(priceImpactWithoutFee)
    ) {
      return;
    }
    if (!swapCallback) {
      return;
    }
    setSwapState({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm,
      swapErrorMessage: undefined,
      txHash: undefined,
    });
    swapCallback()
      .then((hash) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: undefined,
          txHash: hash,
        });

        ReactGA.event({
          category: "Swap",
          action:
            recipient === null
              ? "Swap w/o Send"
              : (recipientAddress ?? recipient) === account
              ? "Swap w/o Send + recipient"
              : "Swap w/ Send",
          label: [
            trade?.inputAmount?.currency?.symbol,
            trade?.outputAmount?.currency?.symbol,
            getTradeVersion(trade),
          ].join("/"),
        });
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        });
      });
  }, [
    tradeToConfirm,
    account,
    priceImpactWithoutFee,
    recipient,
    recipientAddress,
    showConfirm,
    swapCallback,
    trade,
  ]);

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false);

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee);

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode);

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({
      showConfirm: false,
      tradeToConfirm,
      attemptingTxn,
      swapErrorMessage,
      txHash,
    });
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, "");
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash]);

  const handleAcceptChanges = useCallback(() => {
    setSwapState({
      tradeToConfirm: trade,
      swapErrorMessage,
      txHash,
      attemptingTxn,
      showConfirm,
    });
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash]);

  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false); // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency);
    },
    [onCurrencySelection]
  );

  const handleMaxInput = useCallback(() => {
    maxAmountInput && onUserInput(Field.INPUT, maxAmountInput.toExact());
  }, [maxAmountInput, onUserInput]);

  const handleOutputSelect = useCallback(
    (outputCurrency) => onCurrencySelection(Field.OUTPUT, outputCurrency),
    [onCurrencySelection]
  );

  // filter token, search BEST
  const allTokens = useAllTokens();
  const filteredTokens: Token[] = useMemo(() => {
    return filterTokens(Object.values(allTokens), "TEAsWAP.ART");
  }, [allTokens]);
  // set OUTPUT for BEST Token
  useEffect(() => {
    if (filteredTokens.length) {
      onCurrencySelection(Field.OUTPUT, filteredTokens[0]);
    }
  }, [onCurrencySelection, filteredTokens]);

  return (
    <>
      <TokenWarningModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokens={urlLoadedTokens}
        onConfirm={handleConfirmTokenWarning}
      />
      {/*<Banner className="page-banner">*/}
      {/*  <img width="100%" src={process.env.PUBLIC_URL + "/swap_banner.png"} />*/}
      {/*</Banner>*/}
      <BodyWrapper>
        <img
          width="131"
          style={{ alignSelf: "center" }}
          src={process.env.PUBLIC_URL + "/0039.webp"}
        />
        <SwapPoolTabs active={"swap"} />
        <Wrapper id="swap-page">
          <WalletSwitch />
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            recipient={recipient}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />

          <AutoColumn gap={"md"}>
            <CurrencyInputPanel
              label={
                independentField === Field.OUTPUT && !showWrap && trade
                  ? t("fromEstimated")
                  : t("from")
              }
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={!atMaxAmountInput}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swap-currency-input"
            />
            <AutoColumn justify="space-between">
              <AutoRow
                justify={isExpertMode ? "space-between" : "center"}
                style={{ padding: "0 1rem" }}
              >
                <ArrowWrapper clickable>
                  <ArrowDown
                    size="16"
                    onClick={() => {
                      setApprovalSubmitted(false); // reset 2 step UI for approvals
                      onSwitchTokens();
                    }}
                    color={
                      currencies[Field.INPUT] && currencies[Field.OUTPUT]
                        ? theme.green
                        : theme.green
                    }
                  />
                </ArrowWrapper>
                {recipient === null && !showWrap && isExpertMode ? (
                  <LinkStyledButton
                    id="add-recipient-button"
                    onClick={() => onChangeRecipient("")}
                  >
                    + {t("addASendOptional")}
                  </LinkStyledButton>
                ) : null}
              </AutoRow>
            </AutoColumn>
            <CurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={handleTypeOutput}
              label={
                independentField === Field.INPUT && !showWrap && trade
                  ? t("toEstimated")
                  : t("to")
              }
              showMaxButton={false}
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              id="swap-currency-output"
            />

            {recipient !== null && !showWrap ? (
              <>
                <AutoRow justify="space-between" style={{ padding: "0 1rem" }}>
                  <ArrowWrapper clickable={false}>
                    <ArrowDown size="16" color={theme.text2} />
                  </ArrowWrapper>
                  <LinkStyledButton
                    id="remove-recipient-button"
                    onClick={() => onChangeRecipient(null)}
                  >
                    - {t("removeSend")}
                  </LinkStyledButton>
                </AutoRow>
                <AddressInputPanel
                  id="recipient"
                  value={recipient}
                  onChange={onChangeRecipient}
                />
              </>
            ) : null}

            {showWrap ? null : (
              <Card padding={".25rem .75rem 0 .75rem"} borderRadius={"6px"}>
                <AutoColumn gap="4px">
                  {Boolean(trade) && (
                    <RowBetween align="center">
                      <Text fontWeight={500} fontSize={14} color={theme.text2}>
                        {t("price")}
                      </Text>
                      <TradePrice
                        price={trade?.executionPrice}
                        showInverted={showInverted}
                        setShowInverted={setShowInverted}
                      />
                    </RowBetween>
                  )}
                  {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                    <RowBetween align="center">
                      <ClickableText
                        fontWeight={500}
                        fontSize={14}
                        color={theme.text2}
                        onClick={toggleSettings}
                      >
                        {t("slippageTolerance")}
                      </ClickableText>
                      <ClickableText
                        fontWeight={500}
                        fontSize={14}
                        color={theme.text2}
                        onClick={toggleSettings}
                      >
                        {allowedSlippage / 100}%
                      </ClickableText>
                    </RowBetween>
                  )}
                </AutoColumn>
              </Card>
            )}
          </AutoColumn>
          {window.ethereum && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                addTSA();
              }}
            >
              Add TSA to Metamask
            </div>
          )}
          <BottomGrouping>
            {!account ? (
              <ButtonLight onClick={toggleWalletModal}>
                {t("connectWallet")}
              </ButtonLight>
            ) : showWrap ? (
              <ButtonPrimary
                disabled={Boolean(wrapInputError)}
                onClick={onWrap}
              >
                {wrapInputError ??
                  (wrapType === WrapType.WRAP
                    ? t("wrap")
                    : wrapType === WrapType.UNWRAP
                    ? t("unwrap")
                    : null)}
              </ButtonPrimary>
            ) : noRoute && userHasSpecifiedInputOutput ? (
              <GreyCard style={{ textAlign: "center" }}>
                <TYPE.main mb="4px">
                  {t("insufficient-liquidity-for-this-trade")}
                </TYPE.main>
              </GreyCard>
            ) : showApproveFlow ? (
              <RowBetween>
                <ButtonConfirmed
                  onClick={approveCallback}
                  disabled={
                    approval !== ApprovalState.NOT_APPROVED || approvalSubmitted
                  }
                  width="48%"
                  altDisabledStyle={approval === ApprovalState.PENDING} // show solid button while waiting
                  confirmed={approval === ApprovalState.APPROVED}
                >
                  {approval === ApprovalState.PENDING ? (
                    <AutoRow gap="6px" justify="center">
                      {t("approving")} <Loader stroke="white" />
                    </AutoRow>
                  ) : approvalSubmitted &&
                    approval === ApprovalState.APPROVED ? (
                    t("approved")
                  ) : (
                    `${t("approve")} ` + currencies[Field.INPUT]?.symbol
                  )}
                </ButtonConfirmed>
                <ButtonError
                  onClick={() => {
                    if (isExpertMode) {
                      handleSwap();
                    } else {
                      setSwapState({
                        tradeToConfirm: trade,
                        attemptingTxn: false,
                        swapErrorMessage: undefined,
                        showConfirm: true,
                        txHash: undefined,
                      });
                    }
                  }}
                  width="48%"
                  id="swap-button"
                  disabled={
                    !isValid ||
                    approval !== ApprovalState.APPROVED ||
                    (priceImpactSeverity > 3 && !isExpertMode)
                  }
                  error={isValid && priceImpactSeverity > 2}
                >
                  <Text fontSize={16} fontWeight={500}>
                    {priceImpactSeverity > 3 && !isExpertMode
                      ? t("priceImpactHigh")
                      : `${t("swap")}${
                          priceImpactSeverity > 2 ? ` ${t("anyway")}` : ""
                        }`}
                  </Text>
                </ButtonError>
              </RowBetween>
            ) : (
              <ButtonError
                onClick={() => {
                  if (isExpertMode) {
                    handleSwap();
                  } else {
                    setSwapState({
                      tradeToConfirm: trade,
                      attemptingTxn: false,
                      swapErrorMessage: undefined,
                      showConfirm: true,
                      txHash: undefined,
                    });
                  }
                }}
                id="swap-button"
                disabled={
                  !isValid ||
                  (priceImpactSeverity > 3 && !isExpertMode) ||
                  !!swapCallbackError
                }
                error={isValid && priceImpactSeverity > 2 && !swapCallbackError}
              >
                <Text fontSize={20} fontWeight={500}>
                  {swapInputError
                    ? swapInputError
                    : priceImpactSeverity > 3 && !isExpertMode
                    ? t("priceImpactTooHigh")
                    : `${t("swap")}${
                        priceImpactSeverity > 2 ? ` ${t("anyway")}` : ""
                      }`}
                </Text>
              </ButtonError>
            )}
            {showApproveFlow && (
              <Column style={{ marginTop: "1rem" }}>
                <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />
              </Column>
            )}
            {isExpertMode && swapErrorMessage ? (
              <SwapCallbackError error={swapErrorMessage} />
            ) : null}
            {betterTradeLinkVersion ? (
              <BetterTradeLink version={betterTradeLinkVersion} />
            ) : toggledVersion !== DEFAULT_VERSION && defaultTrade ? (
              <DefaultVersionLink />
            ) : null}
          </BottomGrouping>

        </Wrapper>
      </BodyWrapper>
      <BottomBtn
        onClick={() => {
          window.open("https://cbridge.celer.network/1/56")
        }}
      >
        <img src={BuyCryptoImg} />
        {/*<div>*/}
        {/*  <img width="32" src={EthereumLogo} alt="ethereum logo" />*/}
        {/*</div>*/}
        {/*<div*/}
        {/*  style={{*/}
        {/*    marginLeft: "20px",*/}
        {/*    display: "flex",*/}
        {/*    flexDirection: "column",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <span>*/}
        {/*    BNB token bridge*/}
        {/*  </span>*/}
        {/*  <span style={{*/}
        {/*    fontSize: "12px",*/}
        {/*  }}>*/}
        {/*    Deposit tokens to the BNB Chain*/}
        {/*  </span>*/}
        {/*</div>*/}
      </BottomBtn>
      <BottomBtn
        onClick={() => {
          navigate('/buycrypto')
        }}
      >
        <img src={BNBBridge} />
        {/*<div*/}
        {/*  style={{*/}
        {/*    display: "flex",*/}
        {/*    alignItems: "center",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <svg*/}
        {/*    style={{*/}
        {/*      width: "32px",*/}
        {/*      marginLeft: 1*/}
        {/*    }}*/}
        {/*    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">*/}
        {/*    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />*/}
        {/*  </svg>*/}
        {/*</div>*/}
        {/*<div*/}
        {/*  style={{*/}
        {/*    marginLeft: "20px",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Buy crypto*/}
        {/*</div>*/}
      </BottomBtn>
      <AdvancedSwapDetailsDropdown trade={trade} />
    </>
  );
}

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 450px;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 16px 24px rgba(0, 0, 0, 0.1), 0px 24px 32px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  padding: 1rem;
  ${MEDIA_QUERY.sm} {
    width: 90%;
    padding: 0;
  }
`;

export const Banner = styled.div`
  width: 100%;
  ${MEDIA_QUERY.sm} {
  }
`;

export const BottomBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;  
  align-items: center;
  //border: 1px solid #EDEDED;
  //background: radial-gradient(182.71% 205.59% at 2.81% 7.69%, rgba(112, 175, 180, 0.2) 0%, rgba(213, 229, 232, 0.2) 100%);
  max-width: 450px;
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
  //padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  color: #60a7ac;
  font-weight: 600;
  font-size: 16px;
  img{
    width: 100%;
  }
`
